import * as zod from 'zod'

import { ProjectAttribute, ProjectAttributeClass } from 'types/projects/attributes'
import { ProjectType } from 'types/projects/projects'

function getRefineAttributeRequired(systemAttribute: ProjectAttribute) {
  return [
    (value: any) => {
      if (!systemAttribute?.required) return true

      return Array.isArray(value) ? !!value?.length : !!value
    },
    {
      message: 'This field is required',
    },
  ] as const
}

export const modalValidationScheme = (hierarchyFields: string[], attributes: ProjectAttribute[] = []) => {
  const { systemAttributes, customAttributes } = attributes.reduce(
    (acc, attribute) => {
      if (attribute.classification === ProjectAttributeClass.SYSTEM) {
        acc.systemAttributes[attribute.contractName] = attribute
      } else {
        acc.customAttributes[attribute.contractName] = attribute
      }

      return acc
    },
    {
      systemAttributes: {} as Record<string, ProjectAttribute>,
      customAttributes: {} as Record<string, ProjectAttribute>,
    },
  )

  const { contextWorkspace, name, description, startDate_endDate, type, processType, ...restSystemAttr } =
    systemAttributes

  return zod
    .object({
      name: zod
        .string()
        .min(3, 'This field is required and should have at least 3 symbols')
        .max(100, 'Name should be from 3 to 100 characters long'),
      type: zod.string().refine(...getRefineAttributeRequired(systemAttributes.type)),
      description: zod
        .string()
        .max(1000, 'Description should have 1000 characters at most')
        .refine(...getRefineAttributeRequired(systemAttributes.description)),
      dueDate: zod
        .date()
        .array()
        .optional()
        .refine(...getRefineAttributeRequired(systemAttributes['startDate_endDate'])),
      processType: zod
        .string()
        .optional()
        .refine(...getRefineAttributeRequired(systemAttributes.processType)),
      hierarchy: zod.object({
        ...hierarchyFields.reduce(
          (previousValue, currentValue, currentIndex) => ({
            ...previousValue,
            [currentValue]: currentIndex === 0 ? zod.string().optional() : zod.string(),
          }),
          {},
        ),
      }),
      // other system attributes
      ...Object.values(restSystemAttr).reduce((acc, attribute) => {
        return {
          ...acc,
          [attribute.contractName]: zod
            .any()
            .optional()
            .refine(...getRefineAttributeRequired(attribute)),
        }
      }, {}),

      //custom attributes
      customAttributes: zod
        .object({
          ...Object.values(customAttributes).reduce((acc, attribute) => {
            return {
              ...acc,
              [attribute.contractName]: zod
                .any()
                .optional()
                .refine(...getRefineAttributeRequired(attribute)),
            }
          }, {}),
        })
        .optional(),

      wrikeConnectionToggle: zod.boolean().optional(),
      wrikeProjectId: zod.string().optional(),
    })
    .refine(
      data => {
        if (data.wrikeConnectionToggle) {
          return /^\d{10}$/.test(data.wrikeProjectId!)
        }
        return true
      },
      {
        message: 'Wrike project ID must have 10 digits.',
        path: ['wrikeProjectId'],
      },
    )
    .refine(
      ({ hierarchy, type }) => {
        if (type === ProjectType.MISCELLANEOUS || type === ProjectType.FINANCE) {
          return true
        }

        return Object.values(hierarchy).some(Boolean)
      },
      {
        message: `Please enter ${hierarchyFields[0].toLowerCase()} of your project.`,
        path: [`hierarchy.${hierarchyFields[0]}`],
      },
    )
}

type FiltersAmount<T> = {
  [K in keyof T]: T[K][] | undefined | string | boolean
}

export const getAppliedFilters = <T>(filters: FiltersAmount<T>): number => {
  return Object.values(filters)
    .filter(value => {
      if (typeof value === 'boolean') {
        return value
      }
      if (typeof value === 'string') {
        return !!value
      }
      return Array.isArray(value) && value.length > 0
    })
    .flat().length
}
